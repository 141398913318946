import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import EventIcon from "@mui/icons-material/Event";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import ChromaView from "../../common/component/ChromaView";
import ConfirmableActionIconButton from "../../common/component/ConfirmableActionIconButton";
import ChecklistControlView from "../component/ChecklistControlView";
import moment from "moment/moment";
import {
  fetchChecklist,
  removeItem,
  updateQuantity,
} from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { buildPrice, renderSymbol } from "../utils/PricingUtils";
import { InputTheme } from "../../common/utils/ThemeUtils";
import styles from "../style/checklist.module.css";

const Metadata = (props) => {
  return (
    <Grid container className={styles.metadata} columns={10} spacing={0.5}>
      <Grid size={10}>
        <label className={styles.metadataLabel}>{props.label}</label>
      </Grid>
      <Grid>{props.children}</Grid>
      <Grid>
        <label className={styles.metadataValue}>{props.value}</label>
      </Grid>
    </Grid>
  );
};

const QuantityInput = (props) => {
  const [quantity, setQuantity] = useState(props.quantity);

  return (
    <TextField
      id="quantityAmount"
      name="quantityAmount"
      value={quantity}
      variant="outlined"
      size="small"
      sx={{ flex: 1 }}
      onChange={(event) => {
        setQuantity(event.target.value);
      }}
      slotProps={{
        htmlInput: {
          type: "number",
        },
        input: {
          endAdornment: props.quantity !== quantity && (
            <div style={{ marginLeft: 5, marginRight: -5 }}>
              <ConfirmableActionIconButton
                onClick={() => {
                  showLoading();
                  updateQuantity(props.uuid, {
                    uuid: props.item,
                    fulfillment: { quantity: quantity },
                  })
                    .then(() => props.onChange(quantity))
                    .finally(hideLoading);
                }}
                message={`Updating the requested quantity will modify your current selection, and this action cannot be undone. Are you sure you want to proceed?`}
              >
                <CheckOutlinedIcon fontSize="small" />
              </ConfirmableActionIconButton>
            </div>
          ),
        },
      }}
    />
  );
};

const ChecklistPage = () => {
  const { uuid } = useParams();
  const [metadata, setMetadata] = useState({});
  const [itemListings, setItemListings] = useState([]);

  useEffect(() => {
    showLoading();
    fetchChecklist(uuid)
      .then((checklist) => {
        setMetadata(checklist.metadata);
        setItemListings(checklist.itemListings);
      })
      .finally(hideLoading);
  }, []);

  const rebuildMetadata = (itemListings) => {
    const suppliers = itemListings.length;
    const items = itemListings.reduce(
      (previous, current) => previous + current.items.length,
      0
    );
    let quantity = 0;
    const priceAmounts = [];
    itemListings
      .map((itemListing) => itemListing.items)
      .forEach((items) => {
        quantity += items
          .map((item) => item.fulfillment.quantity)
          .reduce((previous, current) => previous + current, 0);
        items
          .map((item) => item.fulfillment)
          .forEach((fulfillment) =>
            priceAmounts.push({
              amount: fulfillment.quantity * fulfillment.price.amount,
              currency: fulfillment.price.currency,
            })
          );
      });
    const estimates = priceAmounts.reduce((previous, current) => {
      const estimation = previous.find(
        (element) => element.currency === current.currency
      ) || { currency: current.currency, amount: 0 };
      estimation.amount = estimation.amount + current.amount;
      return [
        ...previous.filter((element) => element.currency !== current.currency),
        estimation,
      ];
    }, []);
    return {
      created: metadata.created,
      suppliers: suppliers,
      items: items,
      quantity: quantity,
      estimates: estimates,
    };
  };

  const rebuildItemListingsAfterItemRemoval = (uuid) => {
    const actualItemListings = [];
    itemListings.forEach((itemListing) => {
      const items = itemListing.items.filter((item) => item.uuid !== uuid);
      if (items.length > 0) {
        actualItemListings.push({ account: itemListing.account, items: items });
      }
    });
    return actualItemListings;
  };

  const rebuildItemListingsAfterQuantityUpdate = (uuid, quantity) => {
    const actualItemListings = [];
    itemListings.forEach((itemListing) => {
      const items = [];
      itemListing.items.forEach((item) => {
        if (item.uuid === uuid) {
          item.fulfillment.quantity = Number(quantity);
        }
        items.push(item);
      });
      actualItemListings.push({ account: itemListing.account, items: items });
    });
    return actualItemListings;
  };

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <div className={styles.headerComponent}>
            <label className={styles.name}>{metadata.name}</label>
            <ChecklistControlView
              reference={metadata.uuid}
              itemListings={itemListings}
            />
          </div>
          <Grid container spacing={3} sx={{ marginTop: "1vw" }}>
            <Grid>
              <Metadata
                label="Created at"
                value={moment(metadata.created).format("DD MMMM YYYY")}
              >
                <EventIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total suppliers" value={metadata.suppliers}>
                <WarehouseOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total items" value={metadata.items}>
                <NumbersOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total quantity" value={metadata.quantity}>
                <InventoryOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata
                label="Estimated price"
                value={buildPrice(metadata.estimates)}
              >
                <SellOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
          </Grid>
          <ThemeProvider theme={InputTheme}>
            <Grid
              container
              columns={13}
              className={styles.scrollable}
              sx={{ padding: "1vw" }}
            >
              <Grid size={5} className={styles.headerLabel}>
                Item
              </Grid>
              <Grid size={1} className={styles.headerLabel}>
                Price
              </Grid>
              <Grid size={2} className={styles.headerLabel}>
                Quantity
              </Grid>
              <Grid size={1} className={styles.headerLabel}>
                Total
              </Grid>
              <Grid size={1} className={styles.headerLabel} />
              <Grid
                size={3}
                className={styles.headerLabel}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Supplier
              </Grid>
              {itemListings.map((itemListing) => {
                const { account, items } = itemListing;
                return [
                  <Grid size={13} sx={{ padding: 1 }} />,
                  <Grid size={10}>
                    <Grid container columns={10}>
                      {items.map((item) => {
                        const { fulfillment } = item;
                        return [
                          <Grid size={5} className={styles.itemRow}>
                            <ChromaView src={item.image} defaultColor="#EEEEEE">
                              <div style={{ width: "5vw" }}>
                                <img
                                  style={{ maxWidth: "5vw", maxHeight: "10vw" }}
                                  src={item.image}
                                  alt={""}
                                />
                              </div>
                            </ChromaView>
                            <div className={styles.attributesComponent}>
                              <label className={styles.itemNameLabel}>
                                {item.name}
                              </label>
                              <label className={styles.itemDescriptionLabel}>
                                {item.description?.length > 300
                                  ? `${item.description.substring(0, 300)}...`
                                  : item.description}
                              </label>
                            </div>
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                            <label style={{ fontWeight: "bold" }}>
                              {renderSymbol(fulfillment.price.currency)}
                              {fulfillment.price.amount}
                            </label>
                          </Grid>,
                          <Grid
                            size={2}
                            className={styles.rowLabel}
                            sx={{ paddingRight: "2vw" }}
                          >
                            <QuantityInput
                              quantity={fulfillment.quantity}
                              uuid={uuid}
                              item={item.uuid}
                              onChange={(quantity) => {
                                const actualItemListings =
                                  rebuildItemListingsAfterQuantityUpdate(
                                    item.uuid,
                                    quantity
                                  );
                                setItemListings(actualItemListings);
                                setMetadata(
                                  rebuildMetadata(actualItemListings)
                                );
                              }}
                            />
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                            <label style={{ fontWeight: "bold" }}>
                              {renderSymbol(fulfillment.price.currency)}
                              {fulfillment.price.amount * fulfillment.quantity}
                            </label>
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                            <ConfirmableActionIconButton
                              onClick={() => {
                                showLoading();
                                removeItem(uuid, item.uuid)
                                  .then(() => {
                                    const actualItemListings =
                                      rebuildItemListingsAfterItemRemoval(
                                        item.uuid
                                      );
                                    setItemListings(actualItemListings);
                                    setMetadata(
                                      rebuildMetadata(actualItemListings)
                                    );
                                  })
                                  .finally(hideLoading);
                              }}
                              message={`Removing this item will permanently delete it from your checklist, and this action cannot be undone. Are you sure you want to proceed?`}
                            >
                              <ClearIcon htmlColor="#333333" fontSize="small" />
                            </ConfirmableActionIconButton>
                          </Grid>,
                          <Grid size={10} sx={{ padding: 1 }} />,
                        ];
                      })}
                    </Grid>
                  </Grid>,
                  <Grid size={3} className={styles.accountRow}>
                    <div className={styles.accountComponent}>
                      <img
                        style={{ maxWidth: "10vw", maxHeight: "10vw" }}
                        src={account.logo}
                        alt={""}
                      />
                      <label className={styles.accountNameLabel}>
                        {account.name}
                      </label>
                      <label className={styles.accountPhoneLabel}>
                        {account.phone}
                      </label>
                      <label className={styles.accountAddressLabel}>
                        {account.address}
                      </label>
                    </div>
                    <div className={styles.listingSummary}>
                      <label className={styles.listingSummaryLabel}>
                        Total items: {items.length}
                      </label>
                      <label className={styles.listingSummaryLabel}>
                        Total amount:{" "}
                        {renderSymbol(items[0].fulfillment.price.currency)}
                        {items
                          .map((item) => item.fulfillment)
                          .map(
                            (fulfillment) =>
                              fulfillment.quantity * fulfillment.price.amount
                          )
                          .reduce((actual, value) => actual + value)}
                      </label>
                    </div>
                  </Grid>,
                ];
              })}
            </Grid>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ChecklistPage;
