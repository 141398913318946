import { executeGetJsonStreamRequest } from "../../common/client/AxiosClient";

export const fetchOrder = (uuid) => {
  return executeGetJsonStreamRequest("/order/" + uuid);
};

export const fetchSubmissions = () => {
  return executeGetJsonStreamRequest("/order/submissions");
};

export const fetchFulfillments = () => {
  return executeGetJsonStreamRequest("/order/fulfillments");
};
