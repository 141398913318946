import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { ThemeProvider } from "@mui/material";
import ChromaView from "../../common/component/ChromaView";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import { renderSymbol } from "../../checklist/utils/PricingUtils";
import { InputTheme } from "../../common/utils/ThemeUtils";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { fetchOrder } from "../client/OrderClient";
import styles from "../style/order.module.css";

const OrderOverviewPage = () => {
  const { uuid } = useParams();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    showLoading();
    fetchOrder(uuid).then(setOrders).finally(hideLoading);
  }, []);

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <ThemeProvider theme={InputTheme}>
            <Grid
              container
              columns={13}
              className={styles.scrollable}
              sx={{ padding: "1vw" }}
            >
              <Grid size={5} className={styles.headerLabel}>
                Item
              </Grid>
              <Grid size={1} className={styles.headerLabel}>
                Price
              </Grid>
              <Grid size={2} className={styles.headerLabel}>
                Quantity
              </Grid>
              <Grid size={1} className={styles.headerLabel}>
                Total
              </Grid>
              <Grid size={1} className={styles.headerLabel} />
              <Grid
                size={3}
                className={styles.headerLabel}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Customer/Supplier
              </Grid>
              {orders.map((order) => {
                const { account, items } = order;
                return [
                  <Grid size={13} sx={{ padding: 1 }} />,
                  <Grid size={10}>
                    <Grid container columns={10}>
                      {items.map((item) => {
                        return [
                          <Grid size={5} className={styles.itemRow}>
                            <ChromaView src={item.image} defaultColor="#EEEEEE">
                              <div style={{ width: "5vw" }}>
                                <img
                                  style={{ maxWidth: "5vw", maxHeight: "10vw" }}
                                  src={item.image}
                                  alt={""}
                                />
                              </div>
                            </ChromaView>
                            <div className={styles.attributesComponent}>
                              <label className={styles.itemNameLabel}>
                                {item.name}
                              </label>
                              <label className={styles.itemDescriptionLabel}>
                                {item.description?.length > 300
                                  ? `${item.description.substring(0, 300)}...`
                                  : item.description}
                              </label>
                            </div>
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                            <label style={{ fontWeight: "bold" }}>
                              {renderSymbol(item.price.currency)}
                              {item.price.amount}
                            </label>
                          </Grid>,
                          <Grid
                            size={2}
                            className={styles.rowLabel}
                            sx={{ paddingRight: "2vw" }}
                          >
                            <label>{item.quantity}</label>
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                            <label style={{ fontWeight: "bold" }}>
                              {renderSymbol(item.price.currency)}
                              {item.price.amount * item.quantity}
                            </label>
                          </Grid>,
                          <Grid size={10} sx={{ padding: 1 }} />,
                        ];
                      })}
                    </Grid>
                  </Grid>,
                  <Grid size={3} className={styles.accountRow}>
                    <div className={styles.accountComponent}>
                      <img
                        style={{ maxWidth: "10vw", maxHeight: "10vw" }}
                        src={account.logo}
                        alt={""}
                      />
                      <label className={styles.accountNameLabel}>
                        {account.name}
                      </label>
                      <label className={styles.accountPhoneLabel}>
                        {account.phone}
                      </label>
                      <label className={styles.accountAddressLabel}>
                        {account.address}
                      </label>
                    </div>
                    <div className={styles.listingSummary}>
                      <label className={styles.listingSummaryLabel}>
                        Total items: {items.length}
                      </label>
                      <label className={styles.listingSummaryLabel}>
                        Total amount: {renderSymbol(items[0].price.currency)}
                        {items
                          .map((item) => item.quantity * item.price.amount)
                          .reduce((actual, value) => actual + value)}
                      </label>
                    </div>
                  </Grid>,
                  <Grid size={13} className={styles.rowLine} />,
                ];
              })}
            </Grid>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default OrderOverviewPage;
