import React, { useState } from "react";
import Modal from "react-modal";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmableActionButton from "../../common/component/ConfirmableActionTextButton";
import moment from "moment/moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputTheme } from "../../common/utils/ThemeUtils";
import { Path } from "../utils/PathUtils";
import styles from "../style/checklist.module.css";
import { createOrder } from "../../common/client/OrderClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";

const contentStyle = {
  content: {
    top: "25vh",
    bottom: "25vh",
    left: "25vw",
    right: "25vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1vh",
    paddingBottom: "1vh",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    backgroundColor: "white",
  },
  overlay: {
    zIndex: 1,
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const ChecklistControlView = (props) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [location, setLocation] = useState();
  const [expectedDate, setExpectedDate] = useState();

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <NavLink
        to={Path.CHECKLISTS}
        style={{ marginRight: 10 }}
        className={styles.button}
      >
        close
      </NavLink>
      <NavLink
        to={"#"}
        className={styles.button}
        onClick={() => setVisible(true)}
      >
        create order
      </NavLink>
      <Modal isOpen={visible} style={contentStyle} onRequestClose={onClose}>
        <IconButton style={closeStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "30vw",
            height: "30vh",
          }}
        >
          <div>
            <ThemeProvider theme={InputTheme}>
              <TextField
                id="delivery-location-input"
                label="Delivery location"
                value={location}
                variant="outlined"
                size="small"
                sx={{ width: "30vw", marginTop: "2vh" }}
                onChange={(event) => {
                  setLocation(event.target.value);
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "30vw", marginTop: "2vh" }}
                  format={"YYYY-MM-DD"}
                  label="Expected order delivery date"
                  onChange={(event) => {
                    const date = event
                      ? moment(event.toDate()).format("YYYY-MM-DD")
                      : event;
                    setExpectedDate(date);
                  }}
                  slotProps={{
                    field: { clearable: true },
                    textField: { size: "small" },
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
          <div style={{ alignSelf: "flex-end", justifySelf: "flex-end" }}>
            <ConfirmableActionButton
              className={styles.button}
              title="submit"
              message={`Are you sure you want to submit your order? Once submitted, it will 
                be processed, and you will receive a notification when it's ready.`}
              onClick={() => {
                showLoading();
                const items = props.itemListings.reduce((previous, current) => {
                  const currentItems = current.items.map((item) => {
                    return {
                      uuid: item.uuid,
                      quantity: item.fulfillment.quantity,
                    };
                  });
                  return [...previous, ...currentItems];
                }, []);
                const order = {
                  metadata: {
                    reference: props.reference,
                  },
                  items: items,
                };
                if (location || expectedDate) {
                  order.fulfillment = {
                    deliveryDate: expectedDate,
                    deliveryLocation: location,
                  };
                }
                createOrder(order)
                  .then(() => navigate(Path.CHECKLISTS))
                  .finally(hideLoading);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChecklistControlView;
