import React, { useEffect, useState } from "react";
import { fetchSubmissions } from "../client/OrderClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import Grid from "@mui/material/Grid2";
import styles from "../style/order.module.css";
import { renderSymbol } from "../../checklist/utils/PricingUtils";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import SearchView from "../../navigation/component/SearchView";
import moment from "moment/moment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

const StatusView = ({ status }) => {
  const renderLabel = (className, value) => {
    return (
      <label className={`${styles.badgeStatus} ${className}`}>{value}</label>
    );
  };

  if (status === "INITIALISED") {
    return renderLabel(styles.ongoingStatus, "initialised");
  } else if (status === "ACCEPTED") {
    return renderLabel(styles.ongoingStatus, "accepted");
  } else if (status === "IN_PROGRESS") {
    return renderLabel(styles.ongoingStatus, "progress");
  } else if (status === "REVIEW") {
    return renderLabel(styles.ongoingStatus, "review");
  } else if (status === "REJECTED") {
    return renderLabel(styles.criticalStatus, "rejected");
  } else if (status === "CANCELED") {
    return renderLabel(styles.criticalStatus, "canceled");
  } else if (status === "COMPLETED") {
    return renderLabel(styles.ongoingStatus, "completed");
  } else if (status === "SHORTAGE") {
    return renderLabel(styles.cautionStatus, "unavailable");
  } else if (status === "DISRUPTED") {
    return renderLabel(styles.criticalStatus, "disrupted");
  } else if (status === "ERRONEOUS") {
    return renderLabel(styles.criticalStatus, "errorened");
  }
};

const OrderRecordPage = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    showLoading();
    fetchSubmissions().then(setOrders).finally(hideLoading);
  }, []);

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <div className={styles.control}>
            <div style={{ width: "20vw" }}>
              <SearchView onSearch={(phrase) => {}} onSearchCancel={() => {}} />
            </div>
          </div>
          <Grid
            container
            columns={21}
            className={styles.scrollable}
            sx={{ borderBottom: "none", borderRadius: "10px 10px 0 0" }}
          >
            <Grid size={2} className={styles.headerLabel}>
              Order
            </Grid>
            <Grid size={2} className={styles.headerLabel}>
              Status
            </Grid>
            <Grid size={2} className={styles.headerLabel}>
              Created
            </Grid>
            <Grid size={5} className={styles.headerLabel}>
              Customer/Supplier
            </Grid>
            <Grid size={4} className={styles.headerLabel}>
              Destination
            </Grid>
            <Grid size={1} className={styles.headerLabel}>
              Items
            </Grid>
            <Grid size={2} className={styles.headerLabel}>
              ETA
            </Grid>
            <Grid size={2} className={styles.headerLabel}>
              Total
            </Grid>
            <Grid size={1} className={styles.headerLabel}></Grid>
            {orders.map((order) => {
              const { metadata, account, fulfillment } = order;
              return [
                <Grid size={2} className={styles.rowLabel}>
                  {`#${metadata.uuid.split("-")[0]}`}
                </Grid>,
                <Grid size={2} className={styles.rowLabel}>
                  <StatusView status={metadata.status} />
                </Grid>,
                <Grid size={2} className={styles.rowLabel}>
                  {moment(metadata.created).format("MMMM DD, YYYY")}
                </Grid>,
                <Grid size={5} className={styles.rowLabel}>
                  {account.name}
                </Grid>,
                <Grid size={4} className={styles.rowLabel}>
                  {fulfillment?.deliveryLocation}
                </Grid>,
                <Grid size={1} className={styles.rowLabel}>
                  {metadata.itemCount}
                </Grid>,
                <Grid size={2} className={styles.rowLabel}>
                  {fulfillment?.deliveryDate
                    ? moment(fulfillment.deliveryDate).format("MMMM DD, YYYY")
                    : undefined}
                </Grid>,
                <Grid size={2} className={styles.rowLabel}>
                  {renderSymbol(metadata.totalAmount.currency)}
                  {metadata.totalAmount.amount}
                </Grid>,
                <Grid
                  size={1}
                  className={styles.rowLabel}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    size="small"
                    onClick={() => navigate("/order/" + metadata.uuid)}
                    // onClick={() => {}}
                  >
                    <KeyboardArrowRightIcon
                      htmlColor="#0d6986"
                      fontSize="small"
                    />
                  </IconButton>
                </Grid>,
                <Grid size={21} className={styles.rowLine} />,
              ];
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OrderRecordPage;
