import axios from "axios";
import { authorisationToken } from "./AuthClient";

const parseNdJsonResponse = (ndJsonData) => {
  if (ndJsonData) {
    return ndJsonData.trim().split("\n").map(JSON.parse);
  } else {
    return [];
  }
};

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 10 * 1000,
});

export const executeGetJsonStreamRequest = (path) => {
  return authorisationToken().then((token) => {
    return axiosClient
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/x-ndjson",
        },
        responseType: "stream",
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          try {
            resolve(parseNdJsonResponse(response.data));
          } catch (error) {
            reject(error);
          }
        });
      });
  });
};

export const executeGetRequest = (path) => {
  return authorisationToken()
    .then((token) =>
      axiosClient.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    )
    .then((response) => {
      return new Promise((resolve, reject) => {
        try {
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    });
};

export default axiosClient;
