import { authorisationToken } from "./AuthClient";
import axiosClient from "./AxiosClient";

export const createOrder = (order) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/order", order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};
